interface FavouritesResponse {
  favorites: Array<string>;
}

export default class FavouritesService {
  async loadFavourites(): Promise<Array<string>> {
    let response = await fetch(
      `${process.env.REACT_APP_PREFERENCES_SERVICE_URL}/v1/favorite-contacts`,
      { credentials: "include" }
    );
    if (response.ok) {
      const data = (await response.json()) as FavouritesResponse;
      return data.favorites;
    } else {
      return [];
    }
  }

  async addFavourite(principalName: string): Promise<void> {
    let response = await fetch(
      `${process.env.REACT_APP_PREFERENCES_SERVICE_URL}/v1/favorite-contacts/${principalName}`,
      {
        credentials: 'include',
        method: "PUT",
      }
    );
    if (!response.ok) {
      throw Error("Cannot add favorite");
    }
  }

  async removeFavourite(principalName: string): Promise<void> {
    let response = await fetch(
      `${process.env.REACT_APP_PREFERENCES_SERVICE_URL}/v1/favorite-contacts/${principalName}`,
      {
        credentials: 'include',
        method: "DELETE",
      }
    );
    if (!response.ok) {
      throw Error("Cannot delete favorite");
    }
  }
}
