import { IProvider, ProviderState, CacheService } from "@microsoft/mgt-element";
import { CustomProxyGraph } from "./CustomProxyGraph";
import * as microsoftTeams from "@microsoft/teams-js";
import { validateScopes } from "../services/scopesService";

CacheService.config.users.isEnabled = true;

export class CustomProxyProvider extends IProvider {
  isInTeams: boolean;
  consentUpdateRequired: boolean;

  constructor(proxyUrl: string, isInTeams: boolean) {
    super();
    this.isInTeams = isInTeams;
    this.graph = new CustomProxyGraph(proxyUrl);
    this.setState(ProviderState.Loading);
    
    validateScopes().then((isScopeValid) => {
      this.consentUpdateRequired = !isScopeValid;
      if (isScopeValid) {
        this.graph
          .api("me")
          .get()
          .then(
            (user) => {
              if (user != null) {
                this.setState(ProviderState.SignedIn);
              } else {
                this.setState(ProviderState.SignedOut);
              }
            },
            (err) => {
              this.setState(ProviderState.SignedOut);
            }
          );
      } else {
        this.setState(ProviderState.SignedOut);
      }
    });
  }

  /**
   * Promise returning token
   *
   * @returns {Promise<string>}
   * @memberof ProxyProvider
   */
  public getAccessToken(): Promise<string> {
    return Promise.resolve("");
  }

  login(): Promise<void> {
    const prompt = this.consentUpdateRequired ? 'prompt=consent' : '';
    return new Promise(async (resolve, reject) => {
      if (this.isInTeams) {
        const context = await microsoftTeams.app.getContext();
        const loginHint = context.user?.loginHint;
        microsoftTeams.authentication
          .authenticate({
            url: `/backend/login-teams?name=${loginHint}&${prompt}`,
            isExternal: false,
          })
          .then(() => {
            console.log("login");
            this.setState(ProviderState.SignedIn);
            resolve();
          })
          .catch((e) => console.error(e.message));
      } else {
        window.location.href = `/backend/login-browser?${prompt}`;
      }
    });
  }

  logout(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      fetch("/backend/logout", {
        method: "POST",
        credentials: "include",
      }).finally(() => {
        this.setState(ProviderState.SignedOut);
        resolve();
      });
    });
  }
}
