import React from "react";
import OutlinedButton from "../common/OutlinedButton";

interface TeamsButtonProps {
  href: string;
  iconName: string;
}

export default function TeamsButton({ href, iconName }: TeamsButtonProps) {
  return (
    <a href={href}>
      <OutlinedButton iconProps={{ iconName: iconName }} />
    </a>
  );
}
