import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import classes from './CustomSpinner.module.css';

export default function CustomSpinner() {
  return (
    <div className={classes.container}>
      <Spinner size={SpinnerSize.large} />
    </div>
  );
}
