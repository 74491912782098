import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorPage from "./ErrorPage";

export default function NotFound() {
  const navigate = useNavigate();

  const onButtonClick = (event) => {
    navigate("/");
  };

  return (
    <ErrorPage
      message="Something went wrong"
      buttonText="Go to main page"
      buttonAction={onButtonClick}
    />
  );
}
