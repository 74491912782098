import Button from "../common/Button";
import React from "react";
import classes from "./ErrorPage.module.css";

interface Props {
  message: string;
  buttonText?: string;
  buttonAction?: any;
}

export default function ErrorPage({
  message,
  buttonText,
  buttonAction,
}: Props) {
  return (
    <div className={`screen ${classes.container}`}>
      <div className={classes.textContainer}>
        <div className={classes.emoji}>😌</div>
        <div>{message}</div>
      </div>
      {buttonAction && <Button text={buttonText} onClick={buttonAction} />}
    </div>
  );
}
