import { getInitials } from "@fluentui/react";

export default class User {
  id: string;
  principalName: string;
  displayName: string;
  surname: string;
  jobTitle: string;
  presence: string;
  email: string;
  phones: string[];
  mobilePhone?: string;
  statusMessage?: string;
  outOfOfficeMessage?: string;

  get initials(): string {
    return getInitials(this.displayName, false);
  }

  constructor(
    id: string,
    principalName: string,
    displayName: string,
    surname: string,
    jobTitle: string,
    presence: string,
    email: string,
    phones: string[],
    mobilePhone?: string,
    statusMessage?: string,
    outOfOfficeMessage?: string
  ) {
    this.id = id;
    this.principalName = principalName;
    this.displayName = displayName;
    this.surname = surname;
    this.jobTitle = jobTitle;
    this.presence = presence;
    this.email = email;
    this.phones = phones;
    this.mobilePhone = mobilePhone;
    this.statusMessage = statusMessage;
    this.outOfOfficeMessage = outOfOfficeMessage;
  }
}

export function parseFromGraphApi(
  usersResponse: Array<any>,
  presenceResponse: Array<any>
): Array<User> {
  const models =
    usersResponse.flatMap((element) => {
      const userPresence = presenceResponse.filter(
        (presenceElement) => element.id == presenceElement.id
      ) as any;
      const presence = userPresence[0].availability;
      const statusMessage = userPresence[0].statusMessage?.message?.content;
      const outOfOfficeStatus = userPresence[0].outOfOfficeSettings?.message;
      let phones = element.businessPhones as string[];
      if (phones) {
        phones = phones.filter((e) => e !== element.mobilePhone);
      }
      const persona = new User(
        element.id,
        element.userPrincipalName,
        element.displayName,
        element.surname,
        element.jobTitle ?? "",
        presence,
        element.mail,
        phones,
        element.mobilePhone,
        statusMessage,
        outOfOfficeStatus
      );
      return persona;
    }) ?? [];
  return models;
}
