export class CustomHTTPMessageHandler {
    async execute(context) {
        const response = await fetch(context.request, context.options);
        if (response.status == 401 && await response.text() !== 'missing_access_token') {
            await fetch('/backend/refresh', { method: 'POST', credentials: 'include' });
            await fetch('/backend/user-service-refresh', { method: 'POST', credentials: 'include' });
            context.response = await fetch(context.request, context.options);
        } else {
            context.response = response;
        }
    }
}