import React, { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function TabBar() {
  const navigate = useNavigate();
  const location = useLocation();
  let defaultTab;
  switch (location.pathname) {
    case "/":
      defaultTab = 0;
      break;
    case "/profile":
      defaultTab = 1;
      break;
    default:
      break;
  }

  const [selectedTab, setSelectedTab] = useState<number>(defaultTab);

  const onSelectTab = (newValue: number) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/profile");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box sx={{ pb: 9, boxSizing: "border-box", maxHeight: 1, height: 1 }}>
        <Outlet />
      </Box>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 10 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={selectedTab}
          onChange={(event, newValue) => {
            onSelectTab(newValue);
          }}
        >
          <BottomNavigationAction label="Search" icon={<SearchIcon />} />
          <BottomNavigationAction label="Profile" icon={<PersonIcon />} />
        </BottomNavigation>
        <div style={{ paddingBottom: 16 }}></div>
      </Paper>
    </>
  );
}
