import { Providers } from "@microsoft/mgt-element";
import User, { parseFromGraphApi } from "./user";

export async function loadUser(principalName?: string): Promise<User> {
  const url = principalName === undefined ? "me" : `/users/${principalName}`;
  return await fetchUser(url);
}

export async function loadSearch(
  searchQuery: string,
  abortSignal?: AbortSignal
): Promise<User[]> {
  const url =
    searchQuery === undefined || searchQuery === ""
      ? "me/people?$filter=personType/class%20eq%20%27Person%27and%20personType/subclass%20eq%20%27OrganizationUser%27"
      : `users?$search="displayName:${searchQuery}" OR "mail:${searchQuery}" OR "jobTitle:${searchQuery}"&$top=25&$filter=accountEnabled eq true`;
  return await fetchUsers(url, abortSignal);
}

export async function loadNamed(
  principalNames: Array<string>
): Promise<User[]> {
  const parametersList = principalNames.map(
    (element) => `"userPrincipalName:${element}"`
  );
  const parameters = parametersList.join(" OR ");
  const url = `users?$search=${parameters}`;
  const result = await fetchUsers(url);
  result.sort((one, two) => (one.surname > two.surname ? 1 : -1));
  return result;
}

export async function loadManager(principalName?: string): Promise<User> {
  const url =
    principalName === undefined
      ? "/me/manager"
      : `/users/${principalName}/manager`;
  return await fetchUser(url);
}

export async function loadSubordinates(managerPrincipalName: string): Promise<User[]> {
  const url = `/users/${managerPrincipalName}/directReports`;
  return await fetchUsers(url);
}

async function fetchUser(url: string): Promise<User> {
  const response = await Providers.globalProvider.graph.api(url).get();
  const presenceResponse = await Providers.globalProvider.graph
    .api(`/users/${response.id}/presence`)
    .version("beta")
    .get();
  const user = parseFromGraphApi([response], [presenceResponse])[0];
  return user;
}

async function fetchUsers(
  url: string,
  abortSignal?: AbortSignal
): Promise<User[]> {
  try {
    const usersResponse = await Providers.globalProvider.graph
      .api(url)
      .options({ signal: abortSignal })
      .header("ConsistencyLevel", "eventual")
      .get();
    const usersResponseArray = usersResponse.value as Array<any>;

    const ids = usersResponseArray.flatMap((element) => element.id);
    if (ids.length > 0) {
      const presenceResponse = await Providers.globalProvider.graph
        .api("/communications/getPresencesByUserId")
        .post({
          ids: ids,
        });
      const presenceList = presenceResponse.value as Array<any>;

      const models = parseFromGraphApi(usersResponseArray, presenceList);
      return models;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}
