import { Providers } from "@microsoft/mgt-element";
import { ScheduleItem } from "@microsoft/microsoft-graph-types";
import { ScheduleParser } from "./ScheduleParser";

export async function loadSchedule(
  date: Date,
  principalName?: string
): Promise<ScheduleItem[]> {
  const from = new Date(date.getTime());
  from.setHours(0);
  const to = new Date(date.getTime());
  to.setHours(23);
  const response = await Providers.globalProvider.graph
    .api(`me/calendar/getSchedule`)
    .post({
      schedules: [principalName],
      startTime: {
        dateTime: from.toISOString(),
        timeZone: "CEST",
      },
      endTime: {
        dateTime: to.toISOString(),
        timeZone: "CEST",
      },
    });

  const scheduleResponse = response.value[0].scheduleItems;

  const parser = new ScheduleParser();
  const events = parser.parse(scheduleResponse);

  return events;
}
