interface ConsentResponse {
  consentGivenAt: Date;
}

export default class ConsentService {
  async loadConsent(): Promise<boolean> {
    let response = await fetch(
      `${process.env.REACT_APP_PREFERENCES_SERVICE_URL}/v1/data-privacy/consent`,
      { credentials: "include" }
    );
    if (response.ok) {
      return true;
    } else {
      if (response.status === 404) {
        return false;
      }
      if (response.status === 401) {
        window.location.href = '/backend/login-browser-user-service';
        return false;
      }
      throw Error("Cannot get consent");
    }
  }

  async agreeToTerms(): Promise<void> {
    let response = await fetch(
      `${process.env.REACT_APP_PREFERENCES_SERVICE_URL}/v1/data-privacy/consent`,
      {
        credentials: "include",
        method: "POST",
      }
    );
    if (!response.ok) {
      throw Error("Cannot accept terms");
    }
  }
}
