import User from "../../services/user";
import React from "react";
import PeopleListElement from "./PeopleListElement";
import classes from "./PeopleList.module.css";

interface Props {
  header?: string;
  items: Array<User>;
}

export default function PeopleList({ header, items }: Props) {
  return (
    <div className={classes.peopleList}>
      <div className={classes.header}>{header}</div>
      {items.map((element) => (
        <PeopleListElement key={element.id} data={element} />
      ))}
    </div>
  );
}
