import { ScheduleItem } from "@microsoft/microsoft-graph-types";

export class ScheduleParser {
  parse(eventArray: any[]): ScheduleItem[] {
    const result: ScheduleItem[] = [];
    eventArray.forEach((element) => {
      if (element.status != "free") {
        const parsedItem = this.parseItem(element);
        result.push(parsedItem);
      }
    });
    return result;
  }

  private parseItem(event: any) {
    const item = {
      start: { dateTime: event.start.dateTime, timeZone: event.start.timeZone },
      end: { dateTime: event.end.dateTime, timeZone: event.end.timeZone },
    };
    return item;
  }
}
