import * as React from "react";
import styles from "./Schedule.module.scss";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

export interface ITask {
  text?: string;
  from?: string;
  to?: string;
}

export interface ExtendedScheduleItem extends MicrosoftGraph.ScheduleItem {
  text?: string;
}

interface Props {
  scheduleItems: MicrosoftGraph.ScheduleItem[];
}

export default function Schedule({ scheduleItems }: Props) {
  const [width, setWidth] = React.useState(0);
  const _pixelPerHour = width / 10;
  const _pixelPerMinute = _pixelPerHour / 60;
  const _positionOffset = -1;
  const _displayHoursBefore = 3;
  const _displayHoursAfter = 7;
  let _hourFrom;
  let _hourTo;
  let _hours;
  const _locale = "de-DE";

  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    const element = ref.current;
    setWidth(ref.current?.offsetWidth ?? 0);
  }, [ref]);

  const _renderHourSection = (hour: number): JSX.Element => {
    return (
      <div className={styles.hourSection} key={hour}>
        <span className={styles.hourLabel}>{hour}</span>
        <div className={styles.hourVerticalDivider}></div>
        <div className={styles.hourHorizontalDivider}></div>
      </div>
    );
  };

  const _getPosition = (hourFactor, minutes) => {
    return (
      _pixelPerHour * hourFactor + _pixelPerMinute * minutes + _positionOffset
    );
  };

  const _renderTaskSection = (
    scheduleItem: ExtendedScheduleItem
  ): JSX.Element => {
    if (!scheduleItem.start || !scheduleItem.start.dateTime) {
      return <div></div>;
    }
    if (!scheduleItem.end || !scheduleItem.end.dateTime) {
      return <div></div>;
    }

    let timeFrom = new Date(scheduleItem.start.dateTime);
    timeFrom = new Date(
      Date.UTC(
        timeFrom.getFullYear(),
        timeFrom.getMonth(),
        timeFrom.getDate(),
        timeFrom.getHours(),
        timeFrom.getMinutes()
      )
    );

    let timeTo = new Date(scheduleItem.end.dateTime);
    timeTo = new Date(
      Date.UTC(
        timeTo.getFullYear(),
        timeTo.getMonth(),
        timeTo.getDate(),
        timeTo.getHours(),
        timeTo.getMinutes()
      )
    );

    let hourFactor = _hours.indexOf(timeFrom.getHours());
    var width =
      ((timeTo.valueOf() - timeFrom.valueOf()) / 1000 / 60) * _pixelPerMinute;

    if (hourFactor != -1) {
      let style = {
        left: _getPosition(hourFactor, timeFrom.getMinutes()) + "px",
        width: width + "px",
        backgroundColor: "#ffaa44",
      };

      return (
        <div
          key={scheduleItem.text}
          style={style}
          title={scheduleItem.text}
          aria-label={scheduleItem.text}
          className={styles.freeBusySection}
        ></div>
      );
    } else {
      return <div></div>;
    }
  };

  const _getScheduleItemText = (
    timeFromStr: string,
    timeToStr: string
  ): string => {
    var options = { hour: "numeric", minute: "numeric" };

    let timeFrom = new Date(timeFromStr);
    timeFrom = new Date(
      Date.UTC(
        timeFrom.getFullYear(),
        timeFrom.getMonth(),
        timeFrom.getDate(),
        timeFrom.getHours(),
        timeFrom.getMinutes()
      )
    );

    let timeTo = new Date(timeToStr);
    timeTo = new Date(
      Date.UTC(
        timeTo.getFullYear(),
        timeTo.getMonth(),
        timeTo.getDate(),
        timeTo.getHours(),
        timeTo.getMinutes()
      )
    );

    return (
      "Busy from" +
      " " +
      timeFrom.toLocaleTimeString(_locale, options as any) +
      " " +
      "until" +
      " " +
      timeTo.toLocaleTimeString(_locale, options as any)
    );
  };

  const _sanitizeTask = (
    scheduleItem: ExtendedScheduleItem
  ): ExtendedScheduleItem => {
    if (!scheduleItem.start || !scheduleItem.start.dateTime) {
      return scheduleItem;
    }
    if (!scheduleItem.end || !scheduleItem.end.dateTime) {
      return scheduleItem;
    }

    const startDate = new Date(scheduleItem.start.dateTime);
    const endDate = new Date(scheduleItem.end.dateTime);

    const dateFrom = new Date();
    dateFrom.setHours(_hourFrom);
    dateFrom.setMinutes(0);
    dateFrom.setSeconds(0);

    const dateTo = new Date();
    dateTo.setHours(_hourTo);
    dateTo.setMinutes(0);
    dateTo.setSeconds(0);

    if (
      startDate.getDay() < dateFrom.getDay() &&
      endDate.getDay() >= dateTo.getDay()
    ) {
      startDate.setDate(dateFrom.getDate());
      startDate.setHours(0);
      startDate.setMinutes(0);
      scheduleItem.start.dateTime = startDate
        .toISOString()
        .replace("Z", "0000");
    }

    if (startDate.getHours() < _hourFrom) {
      startDate.setHours(_hourFrom);
      startDate.setMinutes(0);
      scheduleItem.start.dateTime = startDate
        .toISOString()
        .replace("Z", "0000");
    }

    return scheduleItem;
  };

  const _renderTaskSections = () => {
    let renderedTasks: JSX.Element[] = [];

    if (typeof scheduleItems !== "undefined" && scheduleItems != null) {
      scheduleItems.map((scheduleItem: MicrosoftGraph.ScheduleItem) => {
        if (!scheduleItem.start || !scheduleItem.start.dateTime) {
          return;
        }
        if (!scheduleItem.end || !scheduleItem.end.dateTime) {
          return;
        }
        let extScheduleItem: ExtendedScheduleItem = scheduleItem;
        extScheduleItem.text = _getScheduleItemText(
          scheduleItem.start.dateTime.toString(),
          scheduleItem.end.dateTime.toString()
        );

        scheduleItem = _sanitizeTask(extScheduleItem);

        if (scheduleItem !== null) {
          renderedTasks.push(_renderTaskSection(scheduleItem));
        }
      });
    }
    return renderedTasks;
  };

  const _renderNowSection = (): JSX.Element => {
    let now = new Date();
    let hourFactor = _hours.indexOf(now.getHours());
    let style = {
      left: _getPosition(hourFactor, now.getMinutes()) + "px",
    };
    let text = now.toLocaleTimeString(_locale, {
      hour: "numeric",
      minute: "numeric",
    });
    return (
      <div
        style={style}
        className={styles.nowSection}
        title={text}
        aria-label={text}
      ></div>
    );
  };

  const _renderHourSections = () => {
    var hourSections: JSX.Element[] = [];
    _hours = [];

    let from = new Date();
    from.setHours(from.getHours() - _displayHoursBefore);
    _hourFrom = from.getHours();

    let to = new Date();
    to.setHours(to.getHours() + _displayHoursAfter);
    _hourTo = to.getHours();

    var diff = _displayHoursBefore + _displayHoursAfter;

    for (let i = 0; i < diff; i++) {
      if (i > 0) {
        from.setHours(from.getHours() + 1);
      }

      _hours.push(from.getHours());
      hourSections.push(_renderHourSection(from.getHours()));
    }

    return hourSections;
  };

  return (
    <div ref={ref} className={styles.schedule}>
      <div className={styles.hourContainer}>
        {_renderHourSections().map((element) => {
          return element;
        })}
        {_renderTaskSections().map((element) => {
          return element;
        })}
        {_renderNowSection()}
      </div>
    </div>
  );
}
