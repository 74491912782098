import { BaseButton, DefaultButton, IIconProps, PrimaryButton } from "@fluentui/react";
import React from "react";
import classes from './OutlinedButton.module.css'

interface Props {
  text?: string;
  onClick?: React.MouseEventHandler<
    | HTMLAnchorElement
    | HTMLButtonElement
    | HTMLDivElement
    | BaseButton
    | HTMLSpanElement
  >;
  iconProps?: IIconProps;
  disabled?: boolean;
}

export default function OutlinedButton({ onClick, text, iconProps, disabled }: Props) {
  return (
    <DefaultButton
      className={disabled ? classes.buttonDisabled : classes.buttonEnabled}
      text={text}
      onClick={onClick}
      checked={false}
      iconProps={iconProps}
      disabled={disabled}
    />
  );
}
