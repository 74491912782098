import React from "react";
import { Login, Providers, ProviderState } from "@microsoft/mgt-react";
import classes from "./Authorization.module.css";
import Button from "./common/Button";

interface Props {
  theme: string;
}

export default function Authorization({ theme }: Props) {
  const onLoginClicked = () => {
    if (Providers.globalProvider.login !== undefined) {
      Providers.globalProvider.login();
    }
  };

  return (
    <div className={`screen ${classes.authorization}`} >
      <img src="/igus-logo.svg" className={classes.logo} />
      <div className={classes.loginButton}>
        <Button text="Log in with Microsoft" onClick={onLoginClicked} />
      </div>
    </div>
  );
}
