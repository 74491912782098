import React, { MouseEventHandler } from "react";
import classes from "./TableViewCell.module.css";

interface Props {
  icon?: any;
  content: any;
  accessoryIcon?: any;
  onAccessoryClick?: MouseEventHandler;
}

export default function TableViewCell({
  icon,
  content,
  accessoryIcon,
  onAccessoryClick,
}: Props) {
  return (
    <div className={classes.cell}>
      {icon !== undefined && <div className={classes.icon}>{icon}</div>}
      <div className={classes.content}>{content}</div>
      <div className={classes.icon} onClick={onAccessoryClick}>
        {accessoryIcon}
      </div>
    </div>
  );
}
