import { AppBar, Toolbar } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./CustomAppBar.module.css";
import ArrowBack from "@mui/icons-material/ArrowBack";

interface Props {
  showBackButton?: boolean;
  rightElement?: JSX.Element;
}

export default function CustomAppBar({ showBackButton, rightElement }: Props) {
  const navigate = useNavigate();

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <div className={classes.container}>
          {(showBackButton || false) && (
            <div className={classes.button} onClick={onBackClick}>
              <ArrowBack fontSize="large" />
            </div>
          )}
          <div></div>
          <div className={classes.button}>{rightElement}</div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
