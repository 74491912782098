import React from "react";
import PeopleSearch from "./people_list/search/PeopleSearch";
import Profile from "./profile/Profile";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useTeamsFx } from "@microsoft/teamsfx-react";
import { useMemo, useState } from "react";
import { CustomProxyProvider } from "../proxy/CustomProxyProvider";
import { Providers, ProviderState } from "@microsoft/mgt-react";
import Authorization from "./Authorization";
import TabBar from "./TabBar";
import { initializeIcons } from "@fluentui/react";
import NotFound from "./errors/NotFound";
import Subordinates from "./people_list/subordinates/Subordinates";
import Consent from "./Consent";

export default function App({}) {
  const [authorizationState, setAuthorizationState] = useState<ProviderState>();
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const { loading, teamsfx, error, themeString, inTeams } = useTeamsFx();

  let theme = "mgt-light";
  if (themeString === "dark") {
    theme = "mgt-dark";
  }

  initializeIcons();

  useMemo(() => {
    Providers.globalProvider = new CustomProxyProvider(`/backend/api`, inTeams);
    Providers.globalProvider.onStateChanged(() => {
      setAuthorizationState(Providers.globalProvider.state);
    });
  }, [inTeams]);

  const router = createBrowserRouter([
    {
      path: "",
      element: <TabBar />,
      errorElement: <NotFound />,
      children: [
        { path: "", element: <PeopleSearch /> },
        {
          path: "profile",
          element: <Profile />,
          children: [{ path: ":principalName", element: <Profile /> }],
        },
        {
          path: "subordinates",
          element: <Subordinates />,
          children: [{ path: ":principalName", element: <Subordinates /> }],
        },
      ],
    },
  ]);

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && error && (
        <div className="error">
          Failed init TeamsFx. Please try again later.
        </div>
      )}
      {!loading && teamsfx && (
        <>
          {authorizationState === ProviderState.SignedIn && (
            <>
              {agreedToTerms && <RouterProvider router={router} />}
              {!agreedToTerms && (
                <Consent
                  completion={() => {
                    setAgreedToTerms(true);
                  }}
                />
              )}
            </>
          )}
          {(authorizationState === ProviderState.SignedOut ||
            authorizationState === undefined) && (
            <Authorization theme={theme} />
          )}
        </>
      )}
    </>
  );
}
