import { IPersonaSharedProps, Persona, PersonaSize } from "@fluentui/react";
import React from "react";
import parsePresence from "../../utils/parsePresence";
import { Link } from "react-router-dom";
import classes from "./PeopleListElement.module.css";
import User from "../../services/user";

interface Props {
  data: User;
  onClickHandler?: (id: string) => void;
}

export default function PeopleListElement({ data, onClickHandler }: Props) {
  const persona: IPersonaSharedProps = {
    imageUrl: `/backend/api/v1.0/users/${data.principalName}/photo/$value`,
    imageInitials: data.initials,
    text: data.displayName,
    secondaryText: data.jobTitle,
    presence: parsePresence(data.presence),
  };

  const onClick = () => {
    if (onClickHandler != null) {
      onClickHandler!(data.id);
    }
  };

  return (
    <Link
      className={classes.peopleListElement}
      to={`/profile/${data.principalName}`}
    >
      <Persona {...persona} size={PersonaSize.size56} onClick={onClick} />
    </Link>
  );
}
