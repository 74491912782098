import React from "react";
import { Link } from "react-router-dom";
import classes from "./MailLink.module.css";

interface Props {
  href: string;
  text: string;
}

function LinkTo({ href, text }: Props) {
  return (
    <Link
      className={classes.mailLink}
      to="#"
      onClick={(e) => {
        window.location.href = href;
        e.preventDefault();
      }}
    >
      {text}
    </Link>
  );
}

interface MailProps {
  mail: string;
  text: string;
}

export function MailLink({ text, mail }: MailProps) {
  return <LinkTo text={text} href={`mailto:${mail}`} />;
}

interface PhoneProps {
  phone: string;
  text: string;
}

export function PhoneLink({ text, phone }: PhoneProps) {
  return <LinkTo text={text} href={`tel:${phone}`} />;
}
