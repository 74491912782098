import React, { useState } from "react";
import { sendMessage } from "../../services/messagesService";
import OutlinedButton from "../common/OutlinedButton";

interface CallBackRequestButtonProps {
    principalName: string;
    completion: (success: boolean) => void;
  }
  
  export default function CallBackRequestButton({
    principalName,
    completion,
  }: CallBackRequestButtonProps) {
    const [disabled, setDisabled] = useState(false);
  
    const sendCallbackRequest = async () => {
      try {
        setDisabled(true);
        await sendMessage(principalName);
        completion(true);
      } catch (error) {
        completion(false);
      }
      setDisabled(false);
    };
  
    return (
      <OutlinedButton
        text="I need your help, please call me back ASAP"
        onClick={sendCallbackRequest}
        disabled={disabled}
      />
    );
  }