import { PersonaPresence } from "@fluentui/react";

export default function parsePresence(presence: string): PersonaPresence {
  switch (presence) {
    case "Offline":
      return PersonaPresence.offline;
    case "Available":
      return PersonaPresence.online;
    case "AvailableIdle":
      return PersonaPresence.online;
    case "Away":
      return PersonaPresence.away;
    case "BeRightBack":
      return PersonaPresence.away;
    case "BusyIdle":
      return PersonaPresence.busy;
    case "Busy":
      return PersonaPresence.busy;
    case "DoNotDisturb":
      return PersonaPresence.dnd;
    default:
      return PersonaPresence.none;
  }
}
