import { BaseButton, IIconProps, PrimaryButton } from "@fluentui/react";
import React from "react";
import classes from './Button.module.css'

interface Props {
  text?: string;
  onClick?: React.MouseEventHandler<
    | HTMLAnchorElement
    | HTMLButtonElement
    | HTMLDivElement
    | BaseButton
    | HTMLSpanElement
  >;
  iconProps?: IIconProps;
}

export default function Button({ onClick, text, iconProps }: Props) {
  return (
    <PrimaryButton
      className={classes.button}
      text={text}
      onClick={onClick}
      checked={false}
      iconProps={iconProps}
    />
  );
}
