import { useState, useEffect } from "react";
import React from "react";
import User from "../../../services/user";
import { SearchBox } from "@fluentui/react";
import classes from "./PeopleSearch.module.css";
import CustomSpinner from "../../common/CustomSpinner";
import { loadNamed, loadSearch } from "../../../services/usersService";
import FavouritesService from "../../../services/favourites/favouritesService";
import PeopleList from "../PeopleList";

interface Props {}

export default function PeopleSearch({}: Props) {
  const [peopleList, setPeopleList] = useState<Array<User>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [header, setHeader] = useState("");
  const [abortController, setAbortController] = useState<AbortController>();

  const favouritesService = new FavouritesService();

  useEffect(() => {
    async function fetchData() {
      try {
        if (abortController) {
          abortController.abort();
        }

        // Search
        if (searchString !== null && searchString !== "") {
          const newAbortController = new AbortController();
          setAbortController(newAbortController);
          const models = await loadSearch(
            searchString,
            newAbortController?.signal
          );
          setPeopleList(models);
          setHeader("");
        } else {
          const favourites = await favouritesService.loadFavourites();
          let models: User[];
          if (favourites.length > 0) {
            // Favourites
            models = await loadNamed(favourites);
            setHeader("Favourites");
          } else {
            // Empty search
            models = await loadSearch("");
            setHeader("");
          }
          setPeopleList(models);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setPeopleList([]);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [searchString]);

  return (
    <div className={`screen ${classes.peopleSearch}`}>
      <SearchBox
        className={classes.searchBox}
        placeholder="Search"
        onChange={(_, newValue) => setSearchString(newValue ?? "")}
      />
      {isLoading && <CustomSpinner />}
      {!isLoading && (
        <div className={classes.content}>
          <PeopleList header={header} items={peopleList} />
        </div>
      )}
    </div>
  );
}
