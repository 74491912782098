import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import User from "../../../services/user";
import { loadManager, loadSubordinates } from "../../../services/usersService";
import CustomAppBar from "../../common/CustomAppBar";
import CustomSpinner from "../../common/CustomSpinner";
import SegmentedControl from "../../common/SegmentedControl";
import ErrorPage from "../../errors/ErrorPage";
import PeopleList from "../PeopleList";
import classes from "./Subordinates.module.css";

interface Props {}

enum DataType {
  peers,
  subordinates,
}

export default function Subordinates({}: Props) {
  const { principalName } = useParams();
  const [manager, setManager] = useState<User>();
  const [peopleList, setPeopleList] = useState<Array<User>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataType, setDataType] = useState(DataType.peers);
  const [listHeader, setListHeader] = useState("");

  useEffect(() => {
    async function fetchPeers() {
      try {
        const manager = await loadManager(principalName);
        const peers = await loadSubordinates(manager.principalName);
        setManager(manager);
        setListHeader("Peers");
        setPeopleList(peers);
        setIsLoading(false);
      } catch (error) {
        setPeopleList([]);
        setIsLoading(false);
      }
    }
    async function fetchSubordinates() {
      try {
        const subordinates = await loadSubordinates(principalName);
        setManager(undefined);
        setListHeader("Team members");
        setPeopleList(subordinates);
        setIsLoading(false);
      } catch (error) {
        setPeopleList([]);
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    switch (dataType) {
      case DataType.peers:
        fetchPeers();
        break;
      case DataType.subordinates:
        fetchSubordinates();
        break;
      default:
        fetchPeers();
    }
  }, [dataType]);

  return (
    <div className={"screen"}>
      <CustomAppBar showBackButton={true} />
      <div className={`screen ${classes.subordinates}`}>
        <SegmentedControl
          name="group-1"
          callback={(val) => setDataType(val)}
          defaultIndex={0}
          controlRef={useRef()}
          segments={[
            {
              label: "Peers",
              value: DataType.peers,
              ref: useRef(),
            },
            {
              label: "Team",
              value: DataType.subordinates,
              ref: useRef(),
            },
          ]}
        />
        {isLoading && <CustomSpinner />}
        {!isLoading && peopleList.length === 0 && (
          <ErrorPage message="Nothing to display" />
        )}
        {!isLoading && peopleList.length > 0 && (
          <div className={classes.content}>
            {manager && <PeopleList header="Manager" items={[manager]} />}
            <PeopleList header={listHeader} items={peopleList} />
          </div>
        )}
      </div>
    </div>
  );
}
