import { Graph, chainMiddleware } from "@microsoft/mgt-element";
import { Client, ClientOptions, RetryHandler, RetryHandlerOptions, Middleware, TelemetryHandler } from "@microsoft/microsoft-graph-client";
import { CustomHTTPMessageHandler } from "./CustomHTTPMessageHandler";

export class CustomProxyGraph extends Graph {
    constructor(baseUrl/*: string*/) {
        const middleware/*: Middleware[]*/ = [
            new RetryHandler(new RetryHandlerOptions()),
            new TelemetryHandler(),
            new CustomHTTPMessageHandler()
        ];

        super(
            Client.initWithMiddleware({
                baseUrl,
                fetchOptions: { credentials: 'include' },
                middleware: chainMiddleware(...middleware)
            }/* as ClientOptions*/)
        );
    }
}