import React from "react";
import classes from "./Wrapper.module.css";

interface Props {
  title: string;
  children: any;
}

export default function InputWrapper({ title, children }: Props) {
  return (
    <fieldset className={classes.wrapper}>
        <legend>{title}</legend>
        {children}
    </fieldset>
  );
}
