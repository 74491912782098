import React, { useEffect, useState } from "react";
import classes from "./Consent.module.css";
import Button from "./common/Button";
import CustomSpinner from "./common/CustomSpinner";
import ConsentService from "../services/consent/consentService";
import ErrorPage from "./errors/ErrorPage";
import Snackbar from "@mui/material/Snackbar";

interface Props {
  completion: Function;
}

export default function Consent({ completion }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isError, setIsError] = useState(false);
  const snackBarText = "Something went wrong";

  const consentService = new ConsentService();

  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackBarOpen(false);
  };

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        const consent = await consentService.loadConsent();
        if (consent) {
          completion();
          return;
        } else {
          setIsError(false);
          setIsLoading(false);
        }
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    }
    fetch();
  }, [refresh]);

  const onLoginClicked = async () => {
    try {
      await consentService.agreeToTerms();
      completion();
    } catch (error) {
      setIsSnackBarOpen(true);
    }
  };

  return (
    <div className={`screen ${classes.authorization}`}>
      {isLoading && <CustomSpinner />}
      {!isLoading && (
        <>
          {isError && (
            <ErrorPage
              message="Something went wrong"
              buttonText="Try again"
              buttonAction={setRefresh}
            />
          )}
          {!isError && (
            <>
              <div className={classes.terms}>
                The Contacts app offers features that requires storing user
                specific data (favorite contacts).
                <br />
                Details of our data privacy conditions can be found{" "}
                <a
                  href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                  target="_blank"
                >
                  here
                </a>
              </div>
              <div className={classes.loginButton}>
                <Button text="Accept" onClick={onLoginClicked} />
              </div>
            </>
          )}
        </>
      )}
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={1000}
        onClose={handleSnackBarClose}
        message={snackBarText}
      />
    </div>
  );
}
